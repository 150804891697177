import React, {Component} from 'react';
import classy from '../../utils/classy.js';
import style from './Image.module.scss';

export default class Image extends Component {
    render() {
        const { className, src, alt } = this.props;

        return(
            <img src={src} alt={alt} className={classy(style.image, className )}></img>
        );
    }
}
