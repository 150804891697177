import React, {Component, useState} from 'react';
// import classy from '../../utils/classy';
import { Parallax } from 'react-scroll-parallax';
import style from './JoinTogether.module.scss';
import ParallaxStyle from './ParallaxStyle.css';
import slackBot from './images/general.gif';
import setupGif from './images/setup.gif';
import discord from './images/discord.mp4';
import discordImage from './images/discord-updated.png';
import placeholder from './images/slack-updated.png';
import {Col, Row } from 'react-bootstrap';
import { Section, Heading, HouseCupFeatures, Visible, Button } from '../../components';
import getSoftware from '../../utils/getSoftware.js';
import { useRouteMatch } from 'react-router-dom';

const JoinTogether = ({ enableParallax }) => {
    const [loaded, setLoaded] = useState(false);
    const [tab, setTab] = useState(1);
    const match = useRouteMatch();

    const handleGifTab = () => {
        if (tab === 1) {
            setTab(2);
        } else {
            setTab(1);
        }
    }

    const handleImageLoaded = () => {
        setLoaded(true);
      }

    const software = getSoftware(match);

    return(
        <div>
        <Section kind="special" className={style.section}>
            <Row className={style.row}>
                <Col xs="12" sm="6">
                    <div className={style.header}>
                        <Heading kind="subtitle">
                            {software.joinTogetherSubtitle}
                        </Heading>
                        <Heading kind="h2">
                            {software.joinTogetherTitle}
                        </Heading>
                    </div>
                    <div className={style.content}>
                        <p>{software.joinTogetherText}</p>
                    </div>
                </Col>

                <Col xs="12" sm="6" className={style.col}>
                    <div className={style.media}>
                        {software.platformName === 'Slack' && (
                            <Parallax disabled={!enableParallax} className={style.parallax} x={[10, 0]} y={[10, 0]}>
                                <div style={{ backgroundImage: `url("${tab === 1 ? placeholder : setupGif}")` }} className={style.platformImage}>
                                </div>
                                <Button className={style.button} onClick={handleGifTab}>
                                    {(tab === 1) ? 'See setup' : 'See Slack'}
                                </Button>
                            </Parallax>
                        )}
                        {software.platformName === 'Discord' && 
                            <Parallax disabled={!enableParallax} className={style.parallax} x={[10, 0]} y={[10, 0]}>
                                <div className={style.videoContainer}>
                                    <video autoPlay loop muted playsinline className={style.video} height="550px" title="HouseCup.gg Discord Demo">
                                        <source src={ discord } type="video/mp4" />
                                    </video>
                                </div>
                            </Parallax>
                        }
                        {software.platformName === 'Slack and Discord' && 
                            <Parallax disabled={!enableParallax} className={style.parallax} x={[10, 0]} y={[10, 0]}>
                                <div style={{ backgroundImage: `url("${tab === 1 ? placeholder : discordImage}")` }} className={style.platformImage}>
                                </div>
                                <Button className={style.button} onClick={handleGifTab}>
                                    {(tab === 1) ? 'See Discord' : 'See Slack'}
                                </Button>
                            </Parallax>
                        }
                    </div>
                </Col>

            </Row>
            </Section>
            <Section className={style.section}>
                <HouseCupFeatures />
            </Section>
        </div>
    );
}

export default JoinTogether;