import React, {Component} from 'react';
import style from './Header.module.scss';
import classy from '../../utils/classy';
import {Container } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { Logo, Navigation, Icon, ModalButtons } from '../../components';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.headerRef = React.createRef();
        this.state = {
            sticky: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var ele = this.headerRef.current;
        var top = ele.getBoundingClientRect();

        if(top !== 0) {
            this.setState({
                sticky: true
            });
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    render() {
        const { alwaysSticky } = this.props;

        return(
            <header className={classy(!alwaysSticky && style.unstuck, style.header, this.state.sticky && style.sticky)} ref={this.headerRef}>
                <Container>
                    <div className={style.row}>

                        <div className={style.logo}>
                            <Logo />
                        </div>

                        <div className={style.navigation}>

                            <div className={style.desktopNavigation}>
                                <ul>
                                    <li className={classy(style.navigationItem, style.help)}>
                                        <a href="/docs">Docs</a>
                                    </li> 
                                    <li className={classy(style.navigationItem, style.support)}>
                                        <Link to='/support'>Support</Link>
                                    </li>
                                </ul>
                                <ModalButtons />
                            </div>

                            <Navigation platform="slack" trigger={
                                <Icon className={style.icon}>menu</Icon>
                            }/>

                        </div>
                        
                    </div>
                </Container>
            </header>
        );
    }
}
