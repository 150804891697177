import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './HouseCupHero.module.scss';
import { Parallax } from 'react-scroll-parallax';
import VisibilitySensor from 'react-visibility-sensor';
import {Col, Row, Container } from 'react-bootstrap';
import { Heading, Image, ModalButtons } from '../../components';
import getSoftware from '../../utils/getSoftware.js';
import { withRouter } from 'react-router-dom';

import crest from './images/crest.png';

import girlRed1 from './images/gryff-1.png';
import girlRed2 from './images/gryff-2.png';
import boyRed from './images/gryff-3.png';
import swirl from './images/dodad-1.png';
import tenRed from './images/+10.svg';

import girlGreen from './images/sly-1.png';
import boyGreen from './images/sly-2.png';
import arrow from './images/dodad-2.png';
import twentyFive from './images/+25.svg';

import girlBlue from './images/rav-1.png';
import boyBlue from './images/rav-2.png';
import swirlArrow from './images/dodad-4.png';
import emphasisLeft from './images/dodad-6.png';
import emphasisRight from './images/dodad-5.png';
import fifty from './images/+50.svg';
import crown from './images/crown.svg';

import girlYellow from './images/huff-3.png';
import boyYellow2 from './images/huff-1.png';
import boyYellow1 from './images/huff-2.png';
import zigzag from './images/dodad-3.png';
import tenYellow from './images/+10.svg';

class HouseCupHero extends Component {

    constructor() {
        super();
        this.state = {
            visible: false,
            active: true,
        }
    }

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                visible: isVisible,
                active: false,
            });
        }
    }

    render() {

      const software = getSoftware(this.props.match);
        return(
            <div className={style.HouseCupHero}>

                <Row className={style.scenery}> 
                    <div className={style.cloudContainer}> 
                        <div className={style.cloudLeft}></div>
                        <div className={style.cloudRight}></div>
                        <div className={style.cloudBottom}></div>
                    </div>
                    <div className={style.foreground}>  
                        <Parallax className={style.castleParallax} y={[-5, 20]}> 
                            <div className={style.castle}></div> 
                        </Parallax> 
                        <div className={style.mainHill}></div>
                    </div> 
                    <div className={style.textureContainer}> 

                    </div> 
                    <div className={style.middleground}> 
                        <div className={style.backTreeline}></div> 
                        <div className={style.frontTreeline}></div> 
                    </div> 
                    <div className={style.treeContainer}> 
                        <div className={style.tree}></div>
                    </div>
                </Row> 

                <Container>
                    <Row className={style.row}>
                        
                        <Col xs="12" md="6" className={style.content}>
                            <Heading kind="subtitle"><h1 className={style.subtitle}>{software.heroSubtitle}</h1></Heading>
                            <Heading kind="h2">{software.heroTitle}</Heading>
                            <div className={style.body}>{software.heroText}</div>
                            <div className={style.footer}>
                                <ModalButtons />
                            </div>
                        </Col>

                        <Col xs="12" md="6">
                            <VisibilitySensor onChange={this.onChange} active={this.state.active} partialVisibility>
                                <div className={classy(style.crest, this.state.visible && style.visible)}>

                                    <Image alt="HouseCup.gg Crest" src={crest} className={style.crestMedia}/>

                                    <Image alt="Slytherin Boy" src={boyGreen} className={classy(style.avatar, style.userMed, style.boyGreen)}/>
                                    <Image alt="Slytherin Girl" src={girlGreen} className={classy(style.avatar, style.userLg, style.girlGreen)}/>
                                    <Image alt="Arrow Accent" src={arrow} className={classy(style.arrowAccent, style.accent, style.arrow)}/>
                                    <Image alt="+25 Points" src={twentyFive} className={classy(style.points, style.twentyFive)}/>

                                    <Image alt="Ravenclaw Boy" src={boyBlue} className={classy(style.avatar, style.userMed, style.boyBlue)}/>
                                    <Image alt="Ravenclaw Girl" src={girlBlue} className={classy(style.avatar, style.userLg, style.girlBlue)}/>
                                    <Image alt="Arrow Accent" src={swirlArrow} className={classy(style.arrowAccent, style.accent, style.swirlArrow)}/>
                                    <Image alt="Winner Left Emphasis" src={emphasisLeft} className={classy(style.accent, style.emphasisLeft)}/>
                                    <Image alt="Winner Right Emphasis" src={emphasisRight} className={classy(style.accent, style.emphasisRight)}/>
                                    <Image alt="+50 Points" src={fifty} className={classy(style.points, style.fifty)}/>
                                    <Image alt="Winner Crown Large" src={crown} className={classy(style.crown, style.crownLg)}/>
                                    <Image alt="Winner Crown Small" src={crown} className={classy(style.crown, style.crownSm)}/>

                                    <Image alt="Hufflepuff Boy" src={boyYellow2} className={classy(style.avatar, style.userMed, style.boyYellow2)}/>
                                    <Image alt="Hufflepuff Boy" src={boyYellow1} className={classy(style.avatar, style.userSm, style.boyYellow1)}/>
                                    <Image alt="Hufflepuff Girl" src={girlYellow} className={classy(style.avatar, style.userLg, style.girlYellow)}/>
                                    <Image alt="Zigzag Arrow Accent" src={zigzag} className={classy(style.arrowAccent, style.accent, style.zigzag)}/>
                                    <Image alt="+10 Points" src={tenYellow} className={classy(style.points, style.tenYellow)}/>

                                    <Image alt="Gryffindor Boy" src={boyRed} className={classy(style.avatar, style.userLg, style.boyRed)}/>
                                    <Image alt="Gryffindor Girl" src={girlRed2} className={classy(style.avatar, style.userMed, style.girlRed2)}/>
                                    <Image alt="Gryffindor Girl" src={girlRed1} className={classy(style.userSm, style.girlRed1)}/>
                                    <Image alt="Swirl Arrow Accent" src={swirl} className={classy(style.arrowAccent, style.accent, style.swirl)}/>
                                    <Image alt="+10 Points" src={tenRed} className={classy(style.points, style.tenRed)}/>

                                </div>
                            </VisibilitySensor>
                        </Col>
                        
                    </Row>
                </Container>

            </div>
        );
    }
}

export default withRouter(HouseCupHero);