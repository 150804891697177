import React, {Component} from 'react';
// import classy from '../../utils/classy';
import { Clickable } from '../../components';
import style from './Logo.module.scss';
import botLogo from '../../assets/images/bot-logo.svg';
import getSoftware from '../../utils/getSoftware.js';
import { withRouter } from 'react-router-dom';

class Logo extends Component {
    render() {

        const software = getSoftware(this.props.match);

        return(
            <Clickable href="/" className={style.logo}>

                <div className={style.media}>
                    <img src={botLogo} alt="HouseCup.gg Logo" />
                </div>

                <div className={style.content}>
                    <h5>HouseCup.gg</h5>
                    <div>{software.platformName} Bot</div>
                </div>
                
            </Clickable>
        );
    }
}

export default withRouter(Logo);