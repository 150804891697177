import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './PageNotFound.module.scss';
import VisibilitySensor from 'react-visibility-sensor';
import {Col, Row } from 'react-bootstrap';
import { Heading, Image, AppWrapper, Section } from '../../components';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";

import cauldron from './images/cauldron.png';
import bubble from './images/bubble.png';

class HouseCupHero extends Component {

    constructor() {
        super();
        this.state = {
            visible: false,
            active: true,
        }
    }

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                visible: isVisible,
                active: false,
            });
        }
    }

    render() {
    
        return(
            <AppWrapper headerAlwaysSticky>
              <Helmet>
                <title>404</title>
              </Helmet>
              <Section kind="small" className={style.section}>
                <Row className={style.row}>
                  <Col sm="12">
                    <VisibilitySensor onChange={this.onChange} active={this.state.active} partialVisibility>
                      <div className={classy(style.pot, this.state.visible && style.visible)}>

                          <Image alt="HouseCup.gg Cauldron" src={cauldron} className={style.potMedia}/>
                          <Heading kind="h4" className={style.heading}><span className={style.headingBlockText}>404</span> Page Not Found</Heading>

                          <Image alt="Bubble" src={bubble} className={classy(style.bubble, style.bubble1)}/>
                          <Image alt="Bubble" src={bubble} className={classy(style.bubble, style.bubble2)}/>
                          <Image alt="Bubble" src={bubble} className={classy(style.bubble, style.bubble3)}/>
                          <Image alt="Bubble" src={bubble} className={classy(style.bubble4)}/>

                          <Image alt="Bubble" src={bubble} className={classy(style.bubble, style.bubble5)}/>
                          <Image alt="Bubble" src={bubble} className={classy(style.bubble, style.bubble6)}/>
                          <Image alt="Bubble" src={bubble} className={classy(style.bubble, style.bubble7)}/>
                          <Image alt="Bubble" src={bubble} className={classy(style.bubble8)}/>
                          <Image alt="Bubble" src={bubble} className={classy(style.points, style.bubble9)}/>

                      </div>
                    </VisibilitySensor>
                  </Col>
                  <Col sm="12">
                    <div className={style.bodyContent}>
                      <p className={style.paragraph}>If this problem persists, please contact <a href="mailto:hello@housecup.gg" className={style.paragraphLink}>hello@housecup.gg</a></p>
                    </div>
                  </Col>
                </Row>
              </Section>
            </AppWrapper>
        );
    }
}

export default withRouter(HouseCupHero);