import React, {Component} from 'react';
// import classy from '../../utils/classy';
import style from './style.module.scss';
import {Col, Row } from 'react-bootstrap';
import { LightboxGalleryProvider } from '@audentio/stuff/lib/Lightbox'
import { Image } from '@audentio/stuff/lib/Image'
import finalLeaderboard from './images/final-leaderboard.png';
import points from './images/points.png';
import leaderboard from './images/leaderboard.png';
import houseprefect from './images/prefect-fixed.png';

const HouseCupFeatures = () => {
    return (
        <div>
            <div className={style.title}>
                <h3 className={style.bold}>HouseCup.gg Bot Features</h3>
            </div>


            <LightboxGalleryProvider>
                <Row>
                    <Col xs="12" sm="4" className={style.col}>
                        <Image src={points} alt="Add or remove points" withLightbox className={style.featureImage} htmlWidth="100%" key="1"/>
                        <p className={style.paragraph}>
                            <span className={style.bold}>Add and remove points.</span> Award house points when someone does something great (or give some just because). And when you need to, take points away as well. To give points to a user or team, run command 
                        </p>
                        <span className={style.commandContainer}><code className={style.command}>@housecup [number] point(s) to [@user or @house]</code></span>
                    </Col>

                    <Col xs="12" sm="4" className={style.col}>
                        <Image src={leaderboard} alt="HouseCup.gg Leaderboard" withLightbox className={style.featureImage} htmlWidth="100%" key="2"/>
                        <p className={style.paragraph}>
                            <span className={style.bold}>Stay up to date with top point-earners.</span> Keep engagement with an automatic scoreboard for everyone to see how the houses and individuals are doing. To check the leaderboard, run command
                        </p>
                        <span className={style.commandContainer}><code className={style.command}>@housecup leaderboard</code></span>
                    </Col>

                    <Col xs="12" sm="4" className={style.col}>
                        <Image src={houseprefect} alt="HouseCup.gg Prefect" withLightbox className={style.featureImage} htmlWidth="100%" key="3"/>
                        <p className={style.paragraph}>
                            <span className={style.bold}>Identify house prefects.</span> Top point earners from each house will be awarded the title of House Prefect and the House Prefect from the winning team will be highlighted as the winner at the end of the semester.
                        </p>
                    </Col>
                </Row>
            </LightboxGalleryProvider>
        </div>
    );
}

export default HouseCupFeatures;