import React from 'react';
import classy from '../../utils/classy';
import style from './ModalButtons.module.scss';
import { Button, Clickable } from '../../components';
import { withRouter } from 'react-router-dom';

class ModalButtons extends React.Component {
    
    render() {

        return (
            <div className={style.buttonRow}>
                    <Clickable href="/docs/slack/setup">
                        <Button kind="slack" className={classy(style.button, style.buttonLeft)}>
                            Add to Slack
                        </Button>
                    </Clickable>

                    <Clickable href="/docs/discord/setup">
                        <Button kind="discordLight" className={classy(style.button, style.buttonRight)}>
                            Add to Discord
                        </Button>
                    </Clickable>

                    {/* <Button href="#" kind="genericDark" className={classy(style.button, style.buttonRight)}>
                        Dashboard
                    </Button> */}
            </div>
        );
    }
}

export default withRouter(ModalButtons);