import React, {Component} from 'react';
import style from './AppWrapper.module.scss';
import { Header, Footer, Alert, Button, Clickable } from '../../components';
import {Helmet} from "react-helmet";
import { theme } from '../../theme';
import { ThemeProvider } from '@audentio/stuff/lib/ThemeProvider'

const AppWrapper = ({ children, headerAlwaysSticky, footerHero, overlap }) => {
    return (
        <ThemeProvider theme={theme} defaultMode="dark">

            <div className={style.app}>
                <Helmet
                    titleTemplate="%s | HouseCup.gg">
                </Helmet>
                <div className={style.body}>
                    <div className={style.space} />
                    <Header alwaysSticky={headerAlwaysSticky} />
                    {children}
                </div>
                <div className={style.footerContainer}>
                    <Footer footerHero={footerHero} overlap={overlap} />
                </div>
            </div>

        </ThemeProvider>
    );
}

export default AppWrapper;
