import React, {Component} from 'react';
// import classy from '../../utils/classy'; 
import { Parallax } from 'react-scroll-parallax'; 
import style from './FinalThingsToClick.module.scss'; 
import { Row, Container } from 'react-bootstrap'; 
import botLogo from '../../assets/images/bot-logo.svg'; 
import { Heading, ModalButtons, Image } from '../../components'; 


class FinalThingsToClick extends Component { 
    
    render() { 
        
        return( 
        
            <div className={style.finalThingsToClick}> 
            
                <Row className={style.scenery}> 
            
                    <div className={style.textureContainer}> 

                    </div>  
                    <div className={style.middleground}> 
                        <div className={style.backTreeline}></div> 
                        <Parallax className={style.hoopParallax} y={[-5, 20]}> 
                            <div className={style.hoops}></div> 
                        </Parallax> 
                        <div className={style.frontTreeline}></div> 
                    </div> 

                </Row> 

                <Container> 

                    <Row className={style.row}> 
                        <div className={style.mediaFigure}> 
                            <Image src={botLogo} alt="HouseCup.gg Logo" /> 
                        </div> 
                        <div className={style.content}> 
                            <Heading kind="subtitle" className={style.subtitle}>
                                Free to use · Easy setup
                            </Heading> 
                            <Heading kind="h3">Start winning house points</Heading> 
                            <div className={style.buttonContainer}> 
                                <ModalButtons /> 
                            </div> 
                        </div> 
                    </Row>

                </Container> 
                
            </div> 
                    
        ); 
    } 
} 

export default FinalThingsToClick;