const Phrases = {
    generic: {
        id: 'generic',
        platformName: 'Slack and Discord',

        heroSubtitle: 'Harry Potter™-style houses in Slack and Discord',
        heroTitle: 'Incentivize your team, award house points.',
        heroText: 'Encourage team recognition and gamification in a way your organization will enjoy with this fun, free, and easy to use Harry Potter™ universe and Hogwarts™ inspired custom Slack and Discord bot!',

        joinTogetherSubtitle: 'Have fun, inspire comradery',
        joinTogetherTitle: 'Bring competition by gamifying your team',
        joinTogetherText: 'Build a community on your Slack or Discord focused on teammates acknowledging each other, socializing, and having fun. You don’t have to be a Harry Potter™ fan to enjoy this bot as you can name the houses however you like, but it helps!',
    },
    slack: {
        id: 'slack',
        platformName: 'Slack',

        heroSubtitle: 'Hogwarts™-style houses in Slack',
        heroTitle: 'Incentivize your team, award house points.',
        heroText: 'Encourage team recognition and gamification in a way your organization will enjoy with this fun, free, and easy to use Harry Potter™ universe and Hogwarts™ inspired custom Slack bot!',

        joinTogetherSubtitle: 'Inspire comradery & have fun',
        joinTogetherTitle: 'Bring competition by gamifying your Slack workspace',
        joinTogetherText:'Build a community on your Slack workspace focused on teammates acknowledging each other, socializing, and having fun. You don’t have to be a Harry Potter™ fan to enjoy this bot as you can name the houses however you like, but it helps!',
    },
    discord: {
        id: 'discord',
        platformName: 'Discord',

        heroSubtitle: 'Hogwarts™-style houses in Discord',
        heroTitle: 'Incentivize your team, award house points.',
        heroText: 'Encourage team recognition and gamification in a way your organization will enjoy with this fun, free, and easy to use Harry Potter™ universe and Hogwarts™ inspired custom Discord bot!',

        joinTogetherSubtitle: 'Inspire comradery & have fun',
        joinTogetherTitle: 'Bring competition by gamifying your Discord channel',
        joinTogetherText:'Build a community on your Discord channel focused on teammates acknowledging each other, socializing, and having fun. You don’t have to be a Harry Potter™ fan to enjoy this bot as you can name the houses however you like, but it helps!',
    }
}

export default Phrases;