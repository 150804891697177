import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './Sidebar.module.scss';
import { Card, Clickable, Button, Icon, Heading, DiscordSidebarItem, SlackSidebarItem } from '../../components';

import discord from './images/discord.png';
import slack from './images/slack.svg';
import audentio from './images/audentio-brandmark.png';

export default class Sidebar extends Component {
    render() {
        return(
            <div className={style.sidebarContainer}>
                <div className={classy(style.sidebarItem, style.sidebarItemAudentio)}>
                    <Clickable href="https://www.audent.io/" target="_blank">
                        <div className={style.sidebarItemContent}>
                            <div className={style.sidebarItemImageContainer}>
                                <img src={audentio} alt="Audentio Logo" className={style.sidebarImage}/>
                            </div>
                            <div className={style.sidebarItemTextContainer}>
                                <Heading kind="h6" className={style.sidebarItemHeading}>Audentio</Heading>
                                <p className={style.sidebarItemParagraph}>Learn more about the agency behind HouseCup.gg.</p>
                                <a href="https://www.audent.io/" className={style.sidebarItemButton}>Visit our website</a>
                            </div>
                        </div>
                    </Clickable>
                </div>
                <div className={style.sidebarDonationButton}>
                    <Clickable href="https://fundraising.stjude.org/site/TR/Events/Events?fr_id=135669&pg=entry" target="_blank">
                        <Button className={style.donateButton}>
                            <Icon className={style.donateIcon}>gift-outline</Icon>
                            Donate to St. Jude
                        </Button>
                    </Clickable>
                </div>
            </div>
        );
    }
}
