import React, {Component} from 'react';
import classy from '../../utils/classy'; 
import style from './Footer.module.scss'; 
import { Container } from 'react-bootstrap'; 
import { Link } from 'react-router-dom'; 
import { Icon } from '../../components'; 


class Footer extends Component { 
    
    render() { 
        
        const { footerHero, overlap } = this.props;

        return( 
        
            <div className={classy(style.footer, footerHero && style.footerHero, overlap && style.overlap)}>

                <div className={style.textureContainer}> 
                
                </div>

                <div className={style.scenery}>
                    <div className={style.tree}>

                    </div>
                    <div className={style.trees}>

                    </div>
                </div>

                <div className={style.footerNavigation}>
                    <Container className={style.footerContainer}>
                        <div className={style.menu}>

                            <div> 
                                <ul className={style.footerLinks}> 
                                    <li className={style.navLinkContainer}>
                                        <Link className={style.navLink} to="/">Home</Link>
                                    </li>
                                    <li className={style.navLinkContainer}>
                                        <Link className={style.navLink} to="/docs">Docs</Link>
                                    </li>
                                    <li className={style.navLinkContainer}>
                                        <Link className={style.navLink} to="/privacy-policy">Privacy policy</Link> 
                                    </li>
                                    {/* <li className={style.navLinkContainer}>
                                        <Link className={style.navLink} to="/terms">Terms &amp; Conditions</Link> 
                                    </li> */}
                                    <li className={style.navLinkContainer}>
                                        <Link className={style.navLink} to="/support">Support</Link>
                                    </li> 
                                </ul> 
                            </div> 

                        </div>

                        <div className={style.audentio}>

                            <a alt="Audentio" href="https://www.audent.io/" target="_blank" rel="noopener noreferrer" className={style.audentioCredit}> Made with <Icon>heart</Icon> by <span className={style.audentioCreditLink}>Audentio</span></a> 

                        </div>

                        <div className={style.legal}>

                            <div className={style.copyright}>
                                <p>All names, references and likenesses related to Harry Potter and the Harry Potter universe are copyright J. K. Rowling, Time Warner and associated publishers.</p> 
                            </div>

                            <ul className={style.socialMediaLinks}> 
                                <li className={style.icon}> 
                                    <a aria-label="Twitter" href="https://twitter.com/Audentio" target="_blank" rel="noopener noreferrer"><Icon>twitter</Icon></a> 
                                </li> 
                                <li className={style.icon}> 
                                    <a aria-label="Facebook" href="https://www.facebook.com/Audentio/" target="_blank" rel="noopener noreferrer"><Icon>facebook-box</Icon></a> 
                                </li> 
                                <li className={classy(style.icon, style.linkedin)}> 
                                    <a aria-label="LinkedIn" href="https://www.linkedin.com/company/audentio/" target="_blank" rel="noopener noreferrer"><Icon>linkedin</Icon></a> 
                                </li> 
                            </ul> 

                        </div>
                    </Container>
                </div>

            </div>
            
                    
        ); 
    } 
} 

export default Footer;