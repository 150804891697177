import React from 'react';
import classy from '../../utils/classy';
import style from './Navigation.module.scss';
import {Link} from "react-router-dom";
import { Icon, Canvas, ModalButtons } from '../../components';

export default class downloadModal extends React.Component {

    constructor() {
        super();
        this.state = {
            content: 'download',
            modalVisible: false,
            platform: '',
        }
    }

    setContent = (modal) => {
        this.setState({
            content: modal
        });
    }

    render () {

    const { platform } = this.props;

    const navigationContent = (
        <div className={classy(style.tab, this.state.content === 'download' && style.activeTab)}>

            <ul className={style.navigation}>
                <li className={classy(style.navigationItem, style.help)}>
                    <a href="/docs">Docs</a>
                </li> 
                <li className={style.navigationItem}>
                    <Link to='/support'>Support</Link>
                </li>
                <li className={style.navigationItem}>
                        <ModalButtons />
                </li>
            </ul>

            <ul className={style.socialMediaLinks}> 
                <li className={style.socialMediaIcon}> 
                    <a aria-label="Twitter" href="https://twitter.com/Audentio" target="_blank" rel="noopener noreferrer"><Icon>twitter</Icon></a> 
                </li> 
                <li className={style.socialMediaIcon}> 
                    <a aria-label="Facebook" href="https://www.facebook.com/Audentio/" target="_blank" rel="noopener noreferrer"><Icon>facebook-box</Icon></a> 
                </li> 
                <li className={style.socialMediaIcon}> 
                    <a aria-label="LinkedIn" href="https://www.linkedin.com/company/audentio/" target="_blank" rel="noopener noreferrer"><Icon>linkedin</Icon></a> 
                </li> 
            </ul>
            
        </div>
    );

    return (
        <Canvas
            trigger={
                React.cloneElement(this.props.trigger, {
                    onClick: () => {
                        this.setState({
                            modalVisible: true,
                            platform: this.props.platform
                        })}}
                )
            }
            stateCb={(state) => {
                this.setState({
                    modalVisible: state,
                })
            }}
            visible={this.state.modalVisible}
        >
            <div className={style.setup}>
                <span onClick={() => this.setContent('download')} className={classy(style.backButton, this.state.content !== 'download' && style.active)}>
                    <Icon>arrow-left</Icon> Go back
            </span>
                {navigationContent}
            </div>
        </Canvas>
        
    );
  }
}