import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './SlackSidebarItem.module.scss';
import { Card, Clickable, Button, Icon, Heading } from '../../components';

import discord from './images/discord.png';
import slack from './images/slack.svg';
import audentio from './images/audentio-brandmark.png';

export default class SlackSidebarItem extends Component {
    render() {
        return(
            <div className={style.sidebarContainer}>
                <div className={classy(style.sidebarItem, style.sidebarItemSlack)}>
                    <Clickable href="https://join.slack.com/t/housecup-workspace/shared_invite/zt-19aybb6rn-aD0RwxLmY2KcjrOe6R9dGg">
                        <div className={style.sidebarItemContent}>
                            <div className={style.sidebarItemImageContainer}>
                                <img src={slack} alt="Slack Logo" className={style.sidebarImage}/>
                            </div>
                            <div className={style.sidebarItemTextContainer}>
                                <Heading kind="h5" className={style.sidebarItemHeading}>Need support or just want to chat?</Heading>
                            </div>
                        </div>
                        <div className={style.sidebarItemButtonContainer}>
                            <a href="https://join.slack.com/t/housecup-workspace/shared_invite/zt-19aybb6rn-aD0RwxLmY2KcjrOe6R9dGg" className={style.sidebarItemSlackButton}>Join our server</a>
                        </div>
                    </Clickable>
                </div>
            </div>
        );
    }
}
