import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter, Router, Route, Redirect, Switch } from "react-router-dom";
import { SlackDocumentation, Privacy, Support, Home, PageNotFound, DiscordDocumentation, Documentation, ThankYou, Software, Newsletter } from './routes';
import { Analytics } from './components';
import './css/style.scss';

function App() {
  return (
    <ParallaxProvider>
        <div className="App">
          <BrowserRouter>
              <Route path="/" component={Analytics} />
            <Switch>
              <Route exact path="/" component={Home} />

              <Route path="/docs/slack/commands" component={SlackDocumentation} />
              <Route path="/docs/slack/setup" component={SlackDocumentation} />
              <Route path="/docs/slack/faq" component={SlackDocumentation} />

              <Route path="/docs/discord/commands" component={DiscordDocumentation} />
              <Route path="/docs/discord/setup" component={DiscordDocumentation} />
              <Route path="/docs/discord/faq" component={DiscordDocumentation} />

              <Route exact path="/privacy-policy" component={Privacy} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/docs" component={Documentation} />
              <Route exact path="/thank-you" component={ThankYou} />
              <Route exact path="/newsletter" component={Newsletter} />

              <Route exact path="/:software(slack|discord)" rel="canonical" component={Software} />

              <Route path="*" component={PageNotFound} />

            </Switch>
          </BrowserRouter>
        </div>
    </ParallaxProvider>
  );
}

export default App;
