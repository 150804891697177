import { useCallback, useEffect, useState } from 'react';

export function throttle (func, wait, leading = true) {
    let context;
    let args;
    let result;
    let timeout = null;
    let previous = 0;
    const later = () => {
        previous = leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };

    return function throttledFunction() {
        const now = Date.now();
        if (!previous && leading === false) previous = now;
        const remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && leading) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    }
}

export function useWindowResize(throttleDuration = 200) {
    const [state, setState] = useState({
        windowHeight: window ? window.innerHeight : 0,
        windowWidth: window ? window.innerWidth : 0,
    });
    const resizeHandler = useCallback(
        throttle(() => {
            setState({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
            });
        }, throttleDuration),
        []
    );
    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return function cleanup() {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);
    return state;
}