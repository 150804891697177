import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './DiscordSidebarItem.module.scss';
import { Card, Clickable, Button, Icon, Heading } from '../../components';

import discord from './images/discord.png';
import slack from './images/slack.svg';
import audentio from './images/audentio-brandmark.png';

export default class DiscordSidebarItem extends Component {
    render() {
        return(
            <div className={style.sidebarContainer}>
                <div className={classy(style.sidebarItem, style.sidebarItemDiscord)}>
                    <Clickable href="https://discord.gg/fWhETSh">
                        <div className={style.sidebarItemContent}>
                            <div className={style.sidebarItemImageContainer}>
                                <img src={discord} alt="Discord Logo" className={style.sidebarImage}/>
                            </div>
                            <div className={style.sidebarItemTextContainer}>
                                <Heading kind="h5" className={style.sidebarItemHeading}>Need support or just want to chat?</Heading>
                            </div>
                        </div>
                        <div className={style.sidebarItemButtonContainer}>
                            <a href="https://discord.com/channels/715270246981697546/715278212955111554" className={style.sidebarItemDiscordButton}>Join our server</a>
                        </div>
                    </Clickable>
                </div>
            </div>
        );
    }
}
