import React, { Component } from 'react';
import {AppWrapper, HouseCupHero, TeamsOrIndividuals, JoinTogether, FinalThingsToClick, ArticleCards, CompaniesUsingBot, DashboardFeatures } from '../../components';
import {Helmet} from "react-helmet";
import { useWindowResize } from '../../hooks/useWindowResize.js';

const Home = () => {

      const { windowWidth } = useWindowResize();

      const enableParallax = windowWidth > 800;

        return (
          <AppWrapper footerHero overlap>
            <Helmet>
                <title>HouseCup.gg Bot for Slack and Discord</title>
            </Helmet>
            <HouseCupHero />
            <JoinTogether enableParallax={enableParallax}/>
            <TeamsOrIndividuals enableParallax={enableParallax}/>
            {/* <DashboardFeatures /> - Pending approval */}
            <CompaniesUsingBot />
            <ArticleCards />
            <FinalThingsToClick />
          </AppWrapper>
        );
    }

export default Home;