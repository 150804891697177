import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './SignUpForm.module.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Icon, Heading, Button } from '../../components';

export default class SignUpForm extends Component {

    constructor() {
        super();
        this.state = {
            content: 'download',
            modalVisible: false,
            platform: '',
        }
        this.email = '';
    }

    setContent = (modal) => {
        this.setState({
            content: modal
        });
    }

    render() {

    const { signUpFormSection } = this.props;

    const url = 'https://housecup.us2.list-manage.com/subscribe/post?u=5c71e44d716e14bfcef15c4df&amp;id=7c95a0222c';

    const CustomForm = () => (
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
              return (
                <div>
                    <form >
                        <input name="email" className={style.formInput} onChange={(e) => { this.email = e.target.value; }} />
                        <Button type="submit" size="thin" className={style.formButton} onClick={() => {
                            subscribe({ EMAIL: this.email });
                        }}>Submit</Button>
                    </form>
                    {status === "error" && <div className={classy(style.formMessage, style.formError)}>An internal error occured. Please contact <a href="mailto:hello@housecup.gg" className={style.paragraphLink}>hello@housecup.gg</a> if this problem persists.</div>}
                    {status === "success" && <div className={classy(style.formMessage, style.formConfirmation)}>Thanks for subscribing!</div>}
                </div>
            )}}
        />
      )

        return(
            <div className={classy(style.signUpForm, signUpFormSection && style.signUpFormSection)}>
                <div className={style.formMedia}>
                    <Icon className={style.icon}>email-newsletter</Icon>
                </div>
                <div className={style.formContent}>
                    <div className={style.titleText}>
                        <Heading kind="h6" className={style.heading}>Keep up to date with HouseCup.gg news</Heading>
                        <p className={style.paragraph}>Join the HouseCup.gg newsletter and keep up to date with new updates and HouseCup.gg features.</p>
                    </div>
                    <CustomForm />
                </div>
            </div>
        );
    }
}
