import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './ThankYou.module.scss';
import VisibilitySensor from 'react-visibility-sensor';
import {Col, Row, Container } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import { Heading, SignUpForm, AppWrapper, Image } from '../../components';
import getSoftware from '../../utils/getSoftware.js';
import { withRouter } from 'react-router-dom';

import trophy from './images/trophy-stool.svg';

import confetti1 from './images/confetti-1.svg';
import confetti2 from './images/confetti-2.svg';
import confetti3 from './images/confetti-3.svg';
import confetti4 from './images/confetti-4.svg';
import confetti5 from './images/confetti-5.svg';
import confetti6 from './images/confetti-6.svg';
import confetti7 from './images/confetti-7.svg';
import confetti8 from './images/confetti-8.svg';
import confetti10 from './images/confetti-25.svg';
import confetti11 from './images/confetti-11.svg';
import confetti12 from './images/confetti-12.svg';
import confetti13 from './images/confetti-13.svg';
import confetti14 from './images/confetti-14.svg';
import confetti15 from './images/confetti-15.svg';
import confetti16 from './images/confetti-16.svg';
import confetti17 from './images/confetti-17.svg';
import confetti18 from './images/confetti-18.svg';
import confetti19 from './images/confetti-19.svg';
import confetti20 from './images/confetti-20.svg';
import confetti21 from './images/confetti-21.svg';
import confetti22 from './images/confetti-22.svg';


class HouseCupHero extends Component {

    constructor() {
        super();
        this.state = {
            visible: false,
            active: true,
        }
    }

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                visible: isVisible,
                active: false,
            });
        }
    }

    render() {

        return(
            <AppWrapper headerAlwaysSticky footerMinimal>
                <Helmet>
                    <title>Thank You</title>
                </Helmet>
                <div className={style.thankYouHero}>

                <Container>
                    
                    <Row> 
                        <Col xs="12" md="6" className={style.candleContainer}>
                            <div className={classy(style.candle, style.candleOne)}></div>
                            <div className={classy(style.candle, style.candleTwo)}></div>
                            <div className={classy(style.candle, style.candleThree)}></div>
                            <div className={classy(style.candle, style.candleFour)}></div>
                        </Col>
                        <Col xs="12" md="6" className={style.candleContainer}>
                            <div className={classy(style.candle, style.candleFive)}></div>
                            <div className={classy(style.candle, style.candleSix)}></div>
                            <div className={classy(style.candle, style.candleSeven)}></div>
                            <div className={classy(style.candle, style.candleEight)}></div>
                        </Col>
                    </Row> 

                        <Row className={style.row}>
                            <Col xs="12" md="6" className={style.content}>
                                <Heading kind="h2" className={style.heading}>Thank you for installing HouseCup.gg</Heading>
                                <SignUpForm signUpFormSection />
                            </Col>

                            <Col xs="12" md="6" className={style.trophyContainer}>
                                <VisibilitySensor onChange={this.onChange} active={this.state.active} partialVisibility>
                                        <div className={classy(style.trophy, this.state.visible && style.visible)}>

                                            <Image alt="HouseCup.gg" src={trophy} className={style.trophyMedia}/>

                                            {/* Line Group */}
                                            <Image alt="Line Confetti" src={confetti1} className={classy(style.confetti, style.line, style.confetti1)}/>
                                            <Image alt="Line Confetti" src={confetti2} className={classy(style.confetti, style.line, style.confetti2)}/>
                                            <Image alt="Line Confetti" src={confetti4} className={classy(style.confetti, style.line, style.confetti4)}/>
                                            <Image alt="Line Confetti" src={confetti11} className={classy(style.confetti, style.line, style.confetti11)}/>
                                            <Image alt="Line Confetti" src={confetti12} className={classy(style.confetti, style.line, style.confetti12)}/>
                                            <Image alt="Line Confetti" src={confetti13} className={classy(style.confetti, style.line, style.confetti13)}/>
                                            <Image alt="Line Confetti" src={confetti14} className={classy(style.confetti, style.line, style.confetti14)}/>
                                            <Image alt="Line Confetti" src={confetti15} className={classy(style.confetti, style.line, style.confetti15)}/>
                                            <Image alt="Line Confetti" src={confetti16} className={classy(style.confetti, style.line, style.confetti16)}/>
                                            <Image alt="Line Confetti" src={confetti17} className={classy(style.confetti, style.line, style.confetti17)}/>
                                            <Image alt="Line Confetti" src={confetti18} className={classy(style.confetti, style.line, style.confetti18)}/>
                                            <Image alt="Line Confetti" src={confetti19} className={classy(style.confetti, style.line, style.confetti19)}/>
                                            <Image alt="Line Confetti" src={confetti20} className={classy(style.confetti, style.line, style.confetti20)}/>

                                            {/* Star Group */}
                                            <Image alt="Star Confetti" src={confetti3} className={classy(style.confetti, style.star, style.confetti3)}/>
                                            <Image alt="Star Confetti" src={confetti21} className={classy(style.confetti, style.star, style.confetti21)}/>
                                            <Image alt="Star Confetti" src={confetti22} className={classy(style.confetti, style.star, style.confetti22)}/>
                                            <Image alt="Star Confetti" src={confetti10} className={classy(style.confetti, style.star, style.confetti10)}/>

                                            {/* Circle Group */}
                                            <Image alt="Circle Confetti" src={confetti5} className={classy(style.confetti, style.circle, style.confetti5)}/>
                                            <Image alt="Circle Confetti" src={confetti6} className={classy(style.confetti, style.circle, style.confetti6)}/>
                                            <Image alt="Circle Confetti" src={confetti7} className={classy(style.confetti, style.circle, style.confetti7)}/>
                                            <Image alt="Circle Confetti" src={confetti8} className={classy(style.confetti, style.circle, style.confetti8)}/>

                                        </div>
                                </VisibilitySensor>
                            </Col>
                            
                        </Row>

                    </Container>

                </div>
            </AppWrapper>
        );
    }
}

export default withRouter(HouseCupHero);