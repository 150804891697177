import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './CompaniesUsingBot.module.scss';
import {Col, Row } from 'react-bootstrap';
import { Section, Card, Heading, Clickable, Image } from '../../components';
import columbia from './images/columbia-logo.svg';
import indeed from './images/indeed-logo.svg';
import noora from './images/noora-nobg.png';
import ripple from './images/ripple_logo.png';
import isobar from './images/Isobar.png';
import kafene from './images/Kafene.png';
import lawadvisorventures from './images/LawAdvisorVentures.png';
import merkle from './images/Merkle.png';
import oyster from './images/Oyster.png';
import qubeinvest from './images/QubeInvest.png';
import re from './images/RE.png';
import roundtrip from './images/Roundtrip.png';
import satchel from './images/Satchel.png';
import vcu from './images/VCU.png';

export default class CompaniesUsingBot extends Component {
    render() {
        return(
            <div>
                <Section kind="special" className={style.articleCards}>

                    <Heading className={style.heading} kind="h4">These teams use the HouseCup.gg bot to gamify and collaborate every day</Heading>
                    
                    <Row className={style.companiesUsingBotContainer}>
                        <div className={style.imageContainer}>
                            <Image src={columbia} className={classy(style.image, style.columbiaLogo)}/>
                        </div>
                        <div className={style.imageContainer}>
                            <Image src={noora} className={style.image}/> 
                        </div>
                        <div className={style.imageContainer}>
                            <Image src={indeed} className={style.image}/>
                        </div>
                        <div className={style.imageContainer}>
                            <Image src={ripple} className={style.image}/>
                        </div>
                        
                    </Row>
                    <Row className={style.companiesUsingBotContainer}>
                        <div className={style.imageContainer}>
                            <Image src={kafene} className={style.image}/> 
                        </div>
                        <div className={style.imageContainer}>
                            <Image src={lawadvisorventures} className={classy(style.image, style.wideLogo)}/>
                        </div>
                        <div className={style.imageContainer}>
                            <Image src={roundtrip} className={style.image}/>
                        </div>
                        <div className={style.imageContainer}>
                            <Image src={vcu} className={style.image}/> 
                        </div>
                    </Row>
                </Section>
            </div>
        );
    }
}
