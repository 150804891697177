import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './ArticleCards.module.scss';
import {Col, Row } from 'react-bootstrap';
import { Section, Card, Heading, Clickable, Button } from '../../components';
import enhanceWorkplace from './images/enhance-workplace.png';
import slackBot from './images/slack-bot.png';
import slackDiscord from './images/slack-discord.jpg';
import helpingCommunity from './images/helping-community.png';

export default class ArticleCards extends Component {
    render() {
        return(
            <div>
                <Section kind="large" className={style.articleCards}>
                    <div className={style.title}>
                        <h3 className={style.bold}>Resources</h3>
                    </div>
                    
                    <Row className={style.articleContainer}>
                        <Col xs="12" md="6" lg="3">
                            <Clickable className={style.articleCard} href="https://adjourn.audent.io/helping-with-community-culture-on-slack-and-discord-5fa6941f69e9/" target="_blank">
                                <Card image={helpingCommunity} alt="Illustration of a badge with a trophy" title="Helping with community culture on Slack and Discord" paragraph="In 2019 we built a Slack and Discord bot to help boost camaraderie and we shared it for free. Shortly after, we wrote about how..." button="Read More" className={style.card} />
                            </Clickable>
                        </Col>

                        <Col xs="12" md="6" lg="3">
                            <Clickable className={style.articleCard} href="https://adjourn.audent.io/6-ways-to-enhance-your-workplace-with-slack-ff2abccbbb3e/" target="_blank">
                                <Card image={enhanceWorkplace} alt="Slack logo on dark background with title" title="6 ways to add fun and enhance your workplace with Slack" paragraph="A large portion of our inter-office communication at Audentio goes through Slack. Here's a few tools we utilize to enhance our workplace." button="Read More" className={style.card} />
                            </Clickable>
                        </Col>

                        <Col xs="12" md="6" lg="3">
                            <Clickable className={classy(style.cardMiddle, style.articleCard)} href="https://adjourn.audent.io/improving-morale-with-a-slack-bot/" target="_blank">
                                <Card image={slackBot} alt="Illustration of a shield divided into groups of people" title="Improving morale with a Slack bot" paragraph="Creating and maintaining a good work environment can be a challenge. See how our team improved our work environment by building a Slack..." button="Read More" className={style.card} />
                            </Clickable>
                        </Col>

                        <Col xs="12" md="6" lg="3">
                            <Clickable className={classy(style.cardLast, style.articleCard)} href="https://medium.com/adjourn/sort-your-house-cup-bot-teams-the-fun-way-d06254bd7071" target="_blank">
                                <Card image={slackDiscord} alt="Screenshot of Slack against Discord using the HouseCup.gg Bot" title="Sort your Slack or Discord teams with Harry Potter houses!" paragraph="You've got this great Harry-Potter themed HouseCup.gg bot installed, but now what? How should you fill you houses? No sorting hat required..." button="Read More" className={style.card} />
                            </Clickable>
                        </Col>
                    </Row>

                    <Row className={style.ctaContainer}>
                        <Col lg="12">
                            <Button href="https://adjourn.audent.io/" target="_blank" >View More Articles</Button>
                        </Col>
                    </Row>
                </Section>
            </div>
        );
    }
}
