import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './TeamsOrIndividuals.module.scss';
import { Parallax } from 'react-scroll-parallax';
import { Row, Col } from 'react-bootstrap';
import { Section, Heading, Card, Image } from '../../components';

import alex from './images/red-2.png';
import nick from './images/yellow-2.png';
import sophie from './images/yellow-3.png';
import josh from './images/red-1.png';
import anita from './images/red-3.png';

import olivia from './images/blue-1.png';
import hugo from './images/blue-2.png';
import carla from './images/green-1.png';
import chris from './images/yellow-1.png';

export default class TeamsOrIndividuals extends Component {

    constructor() {
        super();
        this.state = {
            visible: false,
            active: true,
        }
    }

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                visible: isVisible,
                active: false,
            });
        }
    }

    render() {

        const { enableParallax } = this.props;
        
        return(
            <Section kind="dark">
                <Row className={style.row}>
                    
                <div className={style.houseCupTeams}>
                        <Parallax disabled={!enableParallax} className={style.parallax} x={[-20, 0]}>
                            <div className={style.cardContainer}>
                                <div>
                                    <Col sm='12'>
                                        <Heading kind="subtitle" className={style.heading}>@developers</Heading>
                                        <div className={classy(style.cardContainer, style.row)}>
                                            <div className={style.card}>
                                                <Image src={alex} alt="Gryffindor Girl" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Alex</Heading>
                                                <p className={style.cardTeam}>Gryffindor</p>
                                            </div>
                                            <div className={style.card}>
                                                <Image src={nick} alt="Hufflepuff Boy" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Nick L.</Heading>
                                                <p className={style.cardTeam}>Hufflepuff</p>
                                            </div>
                                            <div className={style.card}>
                                                <Image src={sophie} alt="Hufflepuff Girl" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Sophie C.</Heading>
                                                <p className={style.cardTeam}>Hufflepuff</p>
                                            </div>
                                            <div className={classy(style.card, style.joshCard)}>
                                                <Image src={josh} alt="Gryffindor Boy" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Josh Hall</Heading>
                                                <p className={style.cardTeam}>Gryffindor</p>
                                            </div>
                                            <div className={classy(style.card, style.anitaCard)}>
                                                <Image src={anita} alt="Gryffindor Girl" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Anita</Heading>
                                                <p className={style.cardTeam}>Gryffindor</p>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <div className={style.lastRow}>
                                    <Col sm='12'>
                                        <Heading kind="subtitle" className={style.heading}>@sales</Heading>
                                        <div className={ classy(style.cardContainer,style.row)}>
                                            <div className={style.card}>
                                                <Image src={olivia} alt="Ravenclaw Girl" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Olivia E.</Heading>
                                                <p className={style.cardTeam}>Ravenclaw</p>
                                            </div>
                                            <div className={style.card}>
                                                <Image src={hugo} alt="Ravenclaw Boy" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Hugo</Heading>
                                                <p className={style.cardTeam}>Ravenclaw</p>
                                            </div>
                                            <div className={style.card}>
                                                <Image src={carla} alt="Slytherin Girl" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Carla M.</Heading>
                                                <p className={style.cardTeam}>Slytherin</p>
                                            </div>
                                            <div className={classy(style.card, style.chrisCard)}>
                                                <Image src={chris} alt="Hufflepuff Boy" className={style.cardImage}/>
                                                <Heading kind="h6" className={style.cardHeading}>Chris Getz</Heading>
                                                <p className={style.cardTeam}>Hufflepuff</p>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Parallax>
                    </div>

                    <div className={style.sectionContent}>
                        <Col xs='12'>
                            <div className={style.header}>
                                <Heading kind="subtitle">Win together</Heading>
                                <Heading kind="h3">Choose the teams or individuals to be in a house.</Heading>
                            </div>
                            <div className={style.content}>
                                <p>Decide how you want to establish the members of each house; randomly, by work team, location, or whatever suits your organization the best!</p>
                            </div>
                        </Col>
                    </div>

                </Row>
            </Section>
        );
    }
}

