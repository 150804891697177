import React from 'react';
import ReactGA from 'react-ga';

const sendPageChange = (pathname, search = '') => {
    const page = pathname + search;
    ReactGA.pageview(page);
};
export default function Analytics(props) {
    // initialize GA
    const GA_ID = "UA-88085758-5";

    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            ReactGA.initialize(GA_ID);
        }
    }, []);
    
    // track view on pathname/query change
    React.useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            sendPageChange(props.location.pathname, props.location.search);
        }
    }, [props.location.pathname, props.location.search]);
    return null;
}
