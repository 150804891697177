import React from "react";
import classy from "../../utils/classy";
import style from "./NewsletterModal.module.scss";
import { Row, Col } from "react-bootstrap";
import { Heading, Icon, Modal, Button, Clickable, Image } from "..";
import loading from "./images/loading.svg";
import { withRouter } from "../../utils/withRouter";
import HubspotForm from "react-hubspot-form";

export class NewsletterModal extends React.Component {
  constructor() {
    super();
    this.state = {
      content: "download",
      modalVisible: false,
      platform: "",
    };
    this.email = "";
  }

  setContent = (modal) => {
    this.setState({
      content: modal,
    });
  };

  render() {
    const { platform } = this.props;

    const EmbedForm = () => (
      <HubspotForm
        portalId="4911065"
        formId="3ffd2ae2-b71c-490e-89e4-b440fc72d71e"
        submitButtonClass={classy(style.button, style.formButton)}
        redirectUrl={platform === "slack"
        ? "https://bot.housecup.gg/connect/slack"
        : "https://discord.com/oauth2/authorize?client_id=635665265266524180&redirect_uri=https%3A%2F%2Fbot.housecup.gg%2Fconnect%2Fdiscord&scope=bot+identify&response_type=code"}
      />
    );

    const newsletterContent = (
      <div
        className={classy(
          style.tab,
          this.state.content === "download" && style.activeTab
        )}
      >
{/* 
        <Row>
          <Col sm="12">
            <div className={style.header}>
              <Heading kind="h4" align="center">
                Want to keep up to date with HouseCup.gg news?
              </Heading>
              <p className={style.lead}>
                Subscribe to our newsletter to receive news about HouseCup.gg
                updates, new features, and blog posts to help you incentivize
                your team.
              </p>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col sm="12" className={style.formContainer}>
              {/* replaces the existing content with an embed of the hubspot form */}
            <EmbedForm />
          </Col>
        </Row>

        <Row className={style.modalFooter}>
          <Col xs="12" className={style.footerItems}>
            <Clickable
              href={
                platform === "slack"
                  ? "https://bot.housecup.gg/connect/slack"
                  : "https://discord.com/oauth2/authorize?client_id=635665265266524180&redirect_uri=https%3A%2F%2Fbot.housecup.gg%2Fconnect%2Fdiscord&scope=bot+identify&response_type=code"
              }
              className={style.skipButton}
            >
              <p className={style.paragraph}>No thanks</p>
              <Icon className={style.arrowIcon}>arrow-right</Icon>
            </Clickable>
          </Col>
        </Row>
      </div>
    );

    console.log(this.props.trigger);

    return (
      <Modal
        trigger={React.cloneElement(this.props.trigger, {
          onClick: () => {
            if (this.props.trigger.props) {
              this.props.trigger.props.onClick();
            }
            this.setState({
              modalVisible: true,
              platform: this.props.platform,
            });
          },
        })}
        stateCb={(state) => {
          this.setState({
            modalVisible: state,
          });
        }}
        visible={this.state.modalVisible}
        // visible={this.props.visible}
      >
        <div className={style.setup}>
          <span
            onClick={() => this.setContent("download")}
            className={classy(
              style.backButton,
              this.state.content !== "download" && style.active
            )}
          >
            <Icon>arrow-left</Icon> Go back
          </span>
          {newsletterContent}
        </div>
      </Modal>
    );
  }
}

export default withRouter(NewsletterModal);
