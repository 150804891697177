import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './Card.module.scss';
import {Heading, Icon, Image, Button} from '../';

export default class Card extends Component {
    render() {
        const {image, title, paragraph, figureClass, button, buttonClass, align, cardClass, mediaClass, contentClass, imageClass, bodyClass, titleClass, kind, icon, iconClass, color, alt, buttonBig, buttonBigClass} = this.props;
        // align: top, center, stretch
        // kind: horizontal
        // color: light, dark

        return(
            <div className={ classy(style.card, cardClass, (kind && style['card--' + kind]))}>
                <div className={classy(style['align' + align], style.media, mediaClass)}>
                    <div className={classy(style.mediaFigure, figureClass)}>
                        <Image alt={alt} className={imageClass} src={image} />
                        {icon ? <Icon className={classy(style.icon, iconClass)}>{icon}</Icon> : '' }
                    </div>
                    <div className={classy(style.mediaContent, contentClass, (color && style['card--' + color]))}>
                        {title ? <Heading kind="h5" className={classy(style.mediaHeading, titleClass)}>{title}</Heading> : ''}
                        {paragraph ? <div className={classy(style.mediaBody, bodyClass)}>{paragraph}</div> : ''}
                        {button ? <div className={classy(style.mediaButton, buttonClass)}>{button}</div> : ''}
                        {buttonBig ? <Button className={classy(style.mediaButtonBig, buttonBigClass)}>{button}</Button> : ''}
                    </div>
                </div>
            </div>

        );
    }
}
