import React, { Component } from 'react';
import classy from '../../utils/classy';
import { AppWrapper, Section, Heading, Button, Clickable, Icon } from '../../components';
import {Helmet} from "react-helmet";
import style from './Support.module.scss';
import {Col, Row } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form';

export default class Support extends Component {

    render() {
        return (
          <AppWrapper headerAlwaysSticky>
              <Helmet>
                  <title>HouseCup.gg Bot Support</title>
              </Helmet>
              <Section size="xsmall">
                  
                  <Row className={style.row} style={{marginTop: '80px', marginBottom: '80px'}}>

                    {/* <Col md="12" md="6">
                        <Heading className={style.chatHeading} kind="h2">Let's Chat</Heading>
                        <p className={style.paragraph}>A team member will be in touch to discuss how we can help.</p>
                        <HubspotForm
                            portalId='4911065'
                            formId='898682b1-0639-478b-96b2-35ca36cc7de7'
                            region='na1'
                            // onSubmit={() => console.log('Form submitted!')}
                            // onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                            />
                    </Col> */}

                    <Col md="12">
                        <Heading className={style.helpHeading} kind="h1">Need help with HouseCup.gg?</Heading>
                        {/* <p className={style.paragraph}>If you have a question or wnat to give us some feedback, check out our discord server and Slack workspace below and send us a message.</p>
                        <div className={style.buttonRow}>
                            <Button className={style.button} href="https://discord.com/invite/fWhETSh">
                                HouseCup.gg Support Discord
                            </Button>
                            <Button className={style.button} href="https://housecup-workspace.slack.com/join/shared_invite/zt-19aybb6rn-aD0RwxLmY2KcjrOe6R9dGg#/shared-invite/email">
                                HouseCup.gg Support Slack
                            </Button>
                        </div> */}

                        <Clickable href="https://housecup.gg/docs/discord/setup" target="_blank" rel="noopener noreferrer" className={style.documentationWrapper}>
                            <div className={classy(style.icon, style.discordIcon)}><Icon>discord</Icon></div>
                            <div>
                                <Heading className={style.heading} kind="h5">Discord Documentation</Heading>
                                <p>Learn how to use HouseCup.gg in your Discord server.</p>
                            </div>
                        </Clickable>
                        <Clickable href="https://housecup.gg/docs/slack/setup" target="_blank" rel="noopener noreferrer" className={style.documentationWrapper}>
                            <div className={classy(style.icon, style.slackIcon)}><Icon>slack</Icon></div>
                            <div>
                                <Heading className={style.heading} kind="h5">Slack Documentation</Heading>
                                <p>Learn how to use HouseCup.gg in your Slack workspace.</p>
                            </div>
                        </Clickable>
                    </Col>
                    
                    {/* <Col xs="12" md="3" className={style.supportItem}>
                        <Clickable href="https://discord.gg/fWhETSh" target="_blank" rel="noopener noreferrer" className={style.card}>
                            <div className={classy(style.icon, style.discordIcon)}><Icon>discord</Icon></div>
                            <div className={style.supportContent}>
                                <Heading className={style.heading} kind="h5">Join the HouseCup.gg Discord server</Heading>
                                <p className={style.paragraph}>Join the HouseCup.GG discord to ask questions about using the bot in Discord or chat with the Audentio team.</p>
                                <div className={style.buttonRow}>
                                  <Button className={style.button}>
                                      Join Now
                                  </Button>
                                </div>
                            </div>
                        </Clickable>
                    </Col>
                    <Col xs="12" md="3" className={style.supportItem}>
                        <Clickable href="https://join.slack.com/t/housecup-workspace/shared_invite/zt-19aybb6rn-aD0RwxLmY2KcjrOe6R9dGg" target="_blank" rel="noopener noreferrer" className={style.card}>
                            <div className={classy(style.icon, style.slackIcon)}><Icon>slack</Icon></div>
                            <div className={style.supportContent}>
                                <Heading className={style.heading} kind="h5">Join the HouseCup.gg Slack workspace</Heading>
                                <p className={style.paragraph}>Join the HouseCup.GG Slack to ask questions about using the bot in Slack or chat with Audentio.</p>
                                <div className={style.buttonRow}>
                                  <Button className={style.button}>
                                      Join Now
                                  </Button>
                                </div>
                            </div>
                        </Clickable>
                    </Col>
                    <Col xs="12" md="3" className={style.supportItem}>
                        <Clickable href="mailto:hello@housecup.gg" target="_blank" rel="noopener noreferrer" className={style.card}>
                            <div className={classy(style.icon, style.supportIcon)}><Icon>help-box</Icon></div>
                            <div className={style.supportContent}>
                                <Heading className={style.heading} kind="h5">Contact us</Heading>
                                <p className={style.paragraph}>Want to get in touch or discuss ways to expand HouseCup.gg for your community? <a href="mailto:hello@housecup.gg">hello@housecup.gg</a>.</p>
                                <div className={style.buttonRow}>
                                <Button className={style.button}>
                                  Contact Us  
                                </Button>
                                </div>
                            </div>
                        </Clickable>
                    </Col>
                    <Col xs="12" md="3" className={style.supportItem}>
                        <Clickable href="https://forms.clickup.com/f/83y99-2597/R9M25BG1G4HMK81M5G" target="_blank" rel="noopener noreferrer" className={style.card}>
                            <div className={classy(style.icon, style.githubIcon)}><Icon>alert-box-outline</Icon></div>
                            <div className={style.supportContent}>
                                <Heading className={style.heading} kind="h5">Report bugs and submit suggestions</Heading>
                                <p className={style.paragraph}>If you found a bug in HouseCup.gg or have suggestions, let us know!</p>
                                <div className={style.buttonRow}>
                                  <Button className={style.button}>
                                    Submit Issue
                                  </Button>
                                </div>
                            </div>
                        </Clickable>
                    </Col> */}
                  </Row>
              </Section>
          </AppWrapper>
        );
    }
}
