import React, { Component, useState } from 'react';
import classy from '../../utils/classy';
import {Link, Switch, Route, NavLink} from "react-router-dom";
import style from './SlackDocumentation.module.scss';
import slackLogo from './images/slack-logo.svg';
import {Helmet} from "react-helmet";
import getSoftware from '../../utils/getSoftware.js';
import { withRouter } from 'react-router-dom';
import {Col, Row, Container } from 'react-bootstrap';
import { Section, Heading, AppWrapper, Button, Sidebar, Clickable, Icon, NewsletterModal, Image, SlackSidebarItem } from '../../components';
import ReactGA from 'react-ga';

class SlackDocumentation extends React.Component {
    render() {

        const { match } = this.props;

        const software = getSoftware(match);

        const Commands = () => (
            <div className={style.tabs}>
                <Heading className={style.heading} kind="h3">Documentation</Heading>
                <div className={classy(style.commands, style.slackCommands)}>
                    <Heading className={style.heading} kind="h1">HouseCup.gg Slack Commands</Heading>

                    <p className={style.paragraph}>
                        When the following commands are submitted in the Slack channel the House
                        Cup Bot is in, the resulting action will occur.
                    </p>

                    <Heading className={style.heading} kind="h5">General commands</Heading>
                    <ul className={style.bulletedList}>
                        <li><code className={style.command}>@housecup help</code> The available Discord commands will be displayed in the channel.</li>
                        <li><code className={style.command}>@housecup list houses</code> A list of the houses with all of their users will be displayed in the channel along with the total points for the houses.</li>
                        <li><code className={style.command}>@housecup leaderboard</code> A leaderboard will be displayed in the channel showing the houses and users along with their points.</li>
                        <li><code className={style.command}>@housecup [number] point(s) to [@user or @house]</code> The number of points designated will be awarded to the house or user specified. The HouseCup.gg bot will confirm the awarded points in the channel if entered correctly.</li>
                        <li><code className={style.command}>@housecup [number] point(s) from [@user or @house]</code> The number of points designated will be removed from the house or user specified. The HouseCup.gg bot will confirm the removed points in the channel if entered correctly.</li>
                        <li><code className={style.command}>@housecup user @username</code> Gives a user their point and house details.</li>
                    </ul>

                    <Heading className={style.heading} kind="h5">Administrator commands</Heading>
                    <ul className={style.bulletedList}>
                        <li><code className={style.command}>@housecup set point limit to [number]</code> Sets the maximum number of points that can be given or taken at one time.</li>
                        <li><code className={style.command}>@housecup register house @house-name</code> Register a group as a house. Note: For Slack users, you must have a pro account in order to have groups</li>
                        <li><code className={style.command}>@housecup delete house @house-name</code> This will allow you to delete a current house.</li>
                        <li><code className={style.command}>@housecup list houses</code> List houses currently registered for the house cup.</li>
                    </ul>
                </div>

            </div>
        );

        const Setup = () => (
            <div className={style.tabs}>

                <Heading className={style.heading} kind="h1">HouseCup.gg Slack Help</Heading>
                <Button className={style.button} href="https://bot.housecup.gg/login">
                    <Image src={slackLogo} className={style.buttonMedia}/>
                    Add bot to slack
                </Button>
                <p>Need help setting up and using HouseCup.gg for Slack? Check out our documentation, where we cover installing and using the HouseCup.gg Bot for Slack.</p>
                <p><strong>Our documentation includes:</strong></p>
                <ul className={classy(style.bulletedList, style.includedList)}>
                    <li>Installing HouseCup.gg bot for Slack</li>
                    <li>Setting up houses and users</li>
                    <li>Configuring house cup periods</li>
                    <li>Plus the HouseCup.gg dashboard and more!</li>
                </ul>
                <Button className={style.button} href="https://help.housecup.gg/docs/Slack/installing-the-house-cup-bot-in-your-slack-workspace">
                    <Image src={slackLogo} className={style.buttonMedia}/>
                    HouseCup.gg Slack Documentation
                </Button>

            </div>
        );

        const FAQ = () => (
            <div className={style.tabs}>
                <div className={style.FAQ}>
                    <Heading className={style.heading} kind="h3">Slack FAQ</Heading>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">What do you mean by a group?</Heading>
                        <p className={style.answer}>
                        In Slack, they offer a feature known as groups or user
                        groups that must be created within your workspace before adding users to it. After
                        creating and adding users to a group, you would then be able to register them as
                        houses. View
                        this  <a href="https://slack.com/help/articles/212906697-Create-a-user-group" className={style.paragraphLink}>link</a> for
                        more information on Slack groups.
                        </p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">How do I permit myself as an administrator?</Heading>
                        <p className={style.answer}>
                        Enter the HouseCup.gg dashboard and navigate to user management. From this page, click into a user you'd like to make an admin, select "Permit" and then save your changes.
                        </p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">Why isn't the bot registering my group as a house?</Heading>
                        <p className={style.answer}>
                        Be sure that the group you’re attempting to register as a house was created within your workspace settings.
                        </p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">How can I increase the number of points given? I need to be able to award more than 50.</Heading>
                        <p className={style.paragraph}>
                        To increase the number of points given, enter the HouseCup.gg dashboard and navigate to settings. From this page, select the point giving and taking limit you'd like to see.
                        </p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">Is there a function that allows me to see how many points each house and user has?</Heading>
                        <p className={style.answer}>
                            Yes, use the <code className={style.command}>@housecup leaderboard</code> command. You can also use the command <code className={style.command}>@housecup user @username</code>. If you'd like a full list of how many points a house or user has, you can access this information via the HouseCup.gg dashboard user management or group management.
                        </p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">How can I only let Administrators award points?</Heading>
                        <p className={style.paragraph}>
                            To make a HouseCup.gg channel read-only, click the details option in the top-right, then click more. Then, click manage posting permissions to allow only specific users the option to
                            interact with the HouseCup.gg bot.
                        </p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">How can users choose their own houses?</Heading>
                        <p className={style.paragraph}>
                        Currently this isn't supported within our bot, but can be achieved using a third-party bot alongside the HouseCup.gg bot. First, create a roles channel and add the <a href="https://mee6.xyz/">Mee6</a> Discord bot to this channel. Once you add the bot, you can enable the plugin called "Reaction Roles" as this will allow users to react to a post and be auto-assigned a role.</p>
                        <p className={style.paragraph}>
                        If you'd like, you also have the option to randomly sort or sort by criteria via the onboarding page in the HouseCup.gg dashboard.
                        </p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">How long does it take for the bot to notice changes that I’ve made to houses or users?</Heading>
                        <p className={style.paragraph}>It should take at most an hour for changes to be recognized by the bot. If it takes longer than an hour, click the sync button in the HouseCup.gg dashboard to manually sync your changes within the bot.</p>
                    </div>
                    <div className={style.question}>
                        <Heading className={style.heading} kind="h5">Where can I get help figuring out how to use this bot?</Heading>
                        <p className={style.paragraph}>Reach out in the <a href="https://join.slack.com/t/housecup-workspace/shared_invite/zt-19aybb6rn-aD0RwxLmY2KcjrOe6R9dGg" className={style.paragraphLink}>HouseCup.GG Slack</a>.</p>
                    </div>
                    {/* <div className={style.question}>
                        <Heading className={style.heading} kind="h5">I found an issue and/or have a suggestion to help improve this bot. Where can I log it?</Heading>
                        <p className={style.answer}>You can leave them <a href="https://forms.clickup.com/f/83y99-2597/R9M25BG1G4HMK81M5G" className={style.paragraphLink}>here</a>. Thank you for your help!</p>
                    </div> */}
                </div>
            </div>
        );

        const RouterTabs = () => (
            <div className={style.tabs}>
                <div>
                    <Container className={style.menu}>
                        <Row>
                            <ul className={style.tabNavigation}>
                                <li>
                                    <NavLink to="/docs/slack/setup" className={style.navItem} activeClassName={style.active}>Setup</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/docs/slack/commands" className={style.navItem} activeClassName={style.active}>Commands</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/docs/slack/faq" className={style.navItem} activeClassName={style.active}>FAQ</NavLink>
                                </li>
                            </ul>
                        </Row>
                    </Container>
                </div>
                <div className={style.tabs__Content}>
                    <Section size="small">
                        <Row>
                            <Col xs="12" sm="9">
                                <Switch>
                                    <Route path="/docs/slack/setup" component={Setup} />
                                    <Route path="/docs/slack/commands" component={Commands} />
                                    <Route path="/docs/slack/faq" component={FAQ} />
                                </Switch>
                            </Col>
                            <Col xs="12" sm="3" className={style.sidebar}>
                                {/* <SlackSidebarItem /> */}
                                <Sidebar />
                            </Col>
                        </Row>
                    </Section>
                </div>
            </div>
        );

        return (
            <div>
                <AppWrapper headerAlwaysSticky footerMinimal>
                    <Helmet>
                        <title>HouseCup.gg Bot Slack Documentation</title>
                        <script
                            type="text/javascript"
                            id="hs-script-loader"
                            async
                            defer
                            src="//js.hs-scripts.com/4911065.js"
                        />
                    </Helmet>
                    <RouterTabs />
                </AppWrapper>
            </div>
        );
    }
}

export default withRouter(SlackDocumentation);
