import React, {Component} from 'react';
import classy from '../../utils/classy.js';
import style from './Icon.module.scss';

export default class Button extends Component {
    render() {
        const { children, className, onClick, fontSize, color } = this.props;
        // kinds: primary, secondary
        // size: big, small, xsmall, normal

        return(
            <i onClick={onClick} style={{ fontSize: fontSize, color: color }} className={classy(style.icon, style.mdi, style['mdi-' + children], className )}></i>
        );
    }
}
