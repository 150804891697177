import React, { Component } from 'react';
import classy from '../../utils/classy';
import {Link, Switch, Route, NavLink} from "react-router-dom";
import style from './DiscordDocumentation.module.scss';
import {Helmet} from "react-helmet";
import {Col, Row, Container } from 'react-bootstrap';
import { Section, Heading, AppWrapper, Button, Sidebar, Clickable, Icon, NewsletterModal, DiscordSidebarItem } from '../../components';
import ReactGA from 'react-ga';

export default () => {

    const Setup = () => (
        <div className={style.tabs}>

            <Heading className={style.heading} kind="h1">HouseCup.gg Discord Help</Heading>
            <Button kind="discordDark" className={style.button} href="https://bot.housecup.gg/login">
                <Icon className={style.buttonIcon}>discord</Icon>
                Add bot to discord
            </Button>
            <p>Need help setting up and using HouseCup.gg for Discord? Check out our documentation, where we cover installing and using the HouseCup.gg Bot for Discord.</p>
            <p><strong>Our documentation includes:</strong></p>
            <ul className={classy(style.bulletedList, style.includedList)}>
                <li>Installing HouseCup.gg bot for Discord</li>
                <li>Setting up houses and users</li>
                <li>Configuring house cup periods</li>
                <li>Plus the HouseCup.gg dashboard and more!</li>
            </ul>
            <Button kind="discordDark" className={style.button} href="https://help.housecup.gg/docs/Discord/installing-the-house-cup-bot-in-your-server">
                <Icon className={style.buttonIcon}>discord</Icon>
                HouseCup.gg Discord Documentation
            </Button>

        </div>
    );

    const Commands = () => (
        <div className={style.tabs}>
            <Heading className={style.heading} kind="h3">Documentation</Heading>
            <div className={classy(style.commands, style.slackCommands)}>
                <Heading className={style.heading} kind="h1">HouseCup.gg Discord Commands</Heading>

                <p className={style.paragraph}>
                    When the following commands are submitted in the Discord channel the House
                    Cup Bot is in, the resulting action will occur.
                </p>

                <Heading className={style.heading} kind="h5">General commands</Heading>
                <ul className={style.bulletedList}>
                    <li><code className={style.command}>@housecup help</code> The available Discord commands will be displayed in the channel.</li>
                    <li><code className={style.command}>@housecup list houses</code> A list of the houses with all of their users will be displayed in the channel along with the total points for the houses.</li>
                    <li><code className={style.command}>@housecup leaderboard</code> A leaderboard will be displayed in the channel showing the houses and users along with their points.</li>
                    <li><code className={style.command}>@housecup [number] point(s) to [@user or @house]</code> The number of points designated will be awarded to the house or user specified. The HouseCup.gg bot will confirm the awarded points in the channel if entered correctly.</li>
                    <li><code className={style.command}>@housecup [number] point(s) from [@user or @house]</code> The number of points designated will be removed from the house or user specified. The HouseCup.gg bot will confirm the removed points in the channel if entered correctly.</li>
                    <li><code className={style.command}>@housecup user @username</code> Gives a user their point and house details.</li>
                </ul>

                <Heading className={style.heading} kind="h5">Administrator commands</Heading>
                <ul className={style.bulletedList}>
                    <li><code className={style.command}>@housecup set point limit to [number]</code> Sets the maximum number of points that can be given or taken at one time.</li>
                    <li><code className={style.command}>@housecup register house @house-name</code> Register a group as a house.</li>
                    <li><code className={style.command}>@housecup delete house @house-name</code> This will allow you to delete a current house.</li>
                    <li><code className={style.command}>@housecup list houses</code> List houses currently registered for the house cup.</li>
                </ul>
            </div>
        </div>
    );

    const FAQ = () => (
        <div className={style.tabs}>
            <div className={classy(style.FAQ, style.slackFAQ)}>
                <Heading className={style.heading} kind="h3">Discord FAQ</Heading>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">Why isn't my bot responding when using the help command?</Heading>
                    <p className={style.paragraph}>
                    Be sure the bot has the permissions to read and post in the channel you are posting that command in.
                    </p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">How do I permit myself as an administrator?</Heading>
                    <p className={style.paragraph}>
                    Enter the HouseCup.gg dashboard and navigate to user management. From this page, click into a user you'd like to make an admin, select "Permit" and then save your changes.
                    </p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">I’m attempting to register a role as a house but the bot isn’t responding. What am I doing wrong?</Heading>
                    <p className={style.paragraph}>
                    Be sure that the role you’re attempting to register as a house has the following permission: "Allow anyone to @mention this role" notch enabled within the roles section of Server Settings.
                    </p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">Why aren't my users being added to their houses?</Heading>
                    <p className={style.paragraph}>
                    It is best to have users added to houses before registering. If you add a user to a house after the house has been registered, it may take approximately an hour for the change to take effect. To speed up this process, use the sync button in the HouseCup.gg dashboard.
                    </p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">How can I increase the number of points given? I need to be able to award more than 50.</Heading>
                    <p className={style.paragraph}>To increase the number of points given, enter the HouseCup.gg dashboard and navigate to settings. From this page, select the point giving and taking limit you'd like to see.</p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">Is there a function that allows me to see how many points each house and user has?</Heading>
                    <p className={style.paragraph}>Yes, use the <code className={style.command}>@housecup leaderboard</code> command. You can also use the command <code className={style.command}>@housecup user @username</code>. If you'd like a full list of how many points a house or user has, you can access this information via the HouseCup.gg dashboard user management or group management.</p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">How can I only let Administrators award points?</Heading>
                    <p className={style.paragraph}>
                        To make a HouseCup.gg channel read-only, go into the roles settings, click @everyone, and disable the send messages option. Then, create a role for Administrators and enable the send messages option to allow only those
                        with this role to interact with the HouseCup.gg bot.
                    </p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">How can users choose their own houses?</Heading>
                    <p className={style.paragraph}>
                    Currently this isn't supported within our bot, but can be achieved using a third-party bot alongside the HouseCup.gg bot. First, create a roles channel and add the <a href="https://mee6.xyz/">Mee6</a> Discord bot to this channel. Once you add the bot, you can enable the plugin called "Reaction Roles" as this will allow users to react to a post and be auto-assigned a role.</p>
                    <p className={style.paragraph}>
                    If you'd like, you also have the option to randomly sort or sort by criteria via the onboarding page in the HouseCup.gg dashboard.
                    </p>
                </div>
                {/* <div className={style.question}>
                    <Heading className={style.heading} kind="h5">Where can I get help figuring out how to use this bot? </Heading>
                    <p className={style.paragraph}>Reach out at the #support channel on our <a href="https://discord.com/channels/715270246981697546/715278212955111554" className={style.paragraphLink}>Discord server</a>.</p>
                </div> */}
                {/* <div className={style.question}>
                    <Heading className={style.heading} kind="h5">I found an issue and/or have a suggestion to help improve this bot. Where can I log it?</Heading>
                    <p className={style.paragraph}>You can leave them <a href="https://forms.clickup.com/f/83y99-2597/R9M25BG1G4HMK81M5G" className={style.paragraphLink}>here</a>. Thank you for your help!</p>
                </div> */}
                {/* <div className={style.question}>
                    <Heading className={style.heading} kind="h5">Can I test this bot myself?</Heading>
                    <p className={style.paragraph}>Sure! You can test it within the #bot-spam channel of our <a href="https://discord.com/channels/715270246981697546/715278212955111554">Discord server</a>.</p>
                </div> */}
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">How long does it take for the bot to notice changes that I’ve made to houses or users?</Heading>
                    <p className={style.paragraph}>It should take at most an hour for changes to be recognized by the bot. If it takes longer than an hour, click the sync button in the HouseCup.gg dashboard to manually sync your changes within the bot.</p>
                </div>
                <div className={style.question}>
                    <Heading className={style.heading} kind="h5">I created two houses with the same name. How do I delete the other?</Heading>
                    <p className={style.paragraph}>You are able to remove the other house by disabling the house of your choice via the group management page in the HouseCup.gg dashboard. It should take at most an hour for changes to be recognized by the bot.</p>
                </div>
            </div>
        </div>
    );

    const RouterTabs = () => (
        <div className={style.tabs}>
            <div>
                <Container className={style.menu}>
                    <Row>
                        <ul className={style.tabNavigation}>
                            <li>
                                <NavLink to="/docs/discord/setup" className={style.navItem} activeClassName={style.active}>Setup</NavLink>
                            </li>
                            <li>
                                <NavLink to="/docs/discord/commands" className={style.navItem} activeClassName={style.active}>Commands</NavLink>
                            </li>
                            <li>
                                <NavLink to="/docs/discord/faq" className={style.navItem} activeClassName={style.active}>FAQ</NavLink>
                            </li>
                        </ul>
                    </Row>
                </Container>
            </div>
            <div className={style.tabs__Content}>
                <Section size="small">
                    <Row>
                        <Col xs="12" md="9">
                            <Switch>
                                <Route path="/docs/discord/setup" component={Setup} />
                                <Route path="/docs/discord/commands" component={Commands} />
                                <Route path="/docs/discord/faq" component={FAQ} />
                            </Switch>
                        </Col>
                        <Col xs="12" md="3" className={style.sidebar}>
                            {/* <DiscordSidebarItem /> */}
                            <Sidebar />
                        </Col>
                    </Row>
                </Section>
            </div>
        </div>
    );

    return (
        <div>
            <AppWrapper headerAlwaysSticky footerMinimal>
                <Helmet>
                    <title>HouseCup.gg Bot Discord Documentation</title>
                    <script
                        type="text/javascript"
                        id="hs-script-loader"
                        async
                        defer
                        src="//js.hs-scripts.com/4911065.js"
                    />
                </Helmet>
                <RouterTabs />
            </AppWrapper>
        </div>
    )
}
