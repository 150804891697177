import React, {Component} from 'react';
import style from './Button.module.scss';
import classy from '../../utils/classy.js';

export default class Button extends Component {
    render() {
        const { kind, children, onClick, ref, className, size, href, target } = this.props;
        // kinds: primary discordDark, discordLight, slack
        // size: normal, thin

        return(
            <a onClick={onClick} href={href} ref={ref} target={target} className={classy(style.button, className, (kind && style['button--' + kind]), (size && style['button--' + size] ) )}>
                {children}
            </a>
        );
    }
}
