import React, {Component} from 'react';
import style from './Alert.module.scss';
import classy from '../../utils/classy.js';
import { Icon } from '../../components';

export default class Alert extends Component {

    constructor() {
        super();
    
        this.state = {
            isShowing: true
        }
    }

    closeAlertHandler = () => {
        this.setAlertState(false);
    }

    setAlertState = (state) => {
        this.setState({
            isShowing: state,
        });

        if (typeof(this.props.stateCb) === 'function') {
            this.props.stateCb(state);
        }
    }

    render() {
        const { children, className } = this.props;

        return(
            <div className={classy(style.alert, className, this.state.isShowing && style.visible)}>
                <div>
                    {children}
                </div>
                    <Icon className={style.close} onClick={this.closeAlertHandler}>close</Icon>
            </div>
        );
    }
}