import React, {Component, Fragment} from 'react';
import classy from '../../utils/classy';
import { Icon } from '../../components';
import style from './Modal.module.scss';

export default class Modal extends Component {

    constructor() {
    super();

        this.state = {
            isShowing: false
        }
    }

    openModalHandler = () => {
        this.setModalState(true);
    }

    closeModalHandler = () => {
        this.setModalState(false);
    }

    setModalState = (state) => {
        this.setState({
            isShowing: state,
        });

        if (typeof(this.props.stateCb) === 'function') {
            this.props.stateCb(state);
        }
    }

    render() {
        const visible = (typeof (this.props.visible) !== 'undefined') ? this.props.visible : this.state.isShowing;

        return (
          <Fragment>
            <div className={classy(style.modal, visible === true && style.isOpen)}>
              <div onClick={this.closeModalHandler} className={style.modalCloser}></div>
              <div className={style.modalContent}>
                <div className={style.closeButton}>
                  <Icon onClick={this.closeModalHandler}>close</Icon>
                </div>
                {this.props.children}
              </div>
            </div>
            <span className={style.trigger} onClick={this.openModalHandler}>
              {this.props.trigger}
            </span>
          </Fragment>
        );
    }
}
