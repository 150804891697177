import React, { Component } from 'react';
import {AppWrapper, HouseCupHero, TeamsOrIndividuals, JoinTogether, FinalThingsToClick, ArticleCards, CompaniesUsingBot } from '../../components';
import {Helmet} from "react-helmet";
import getSoftware from '../../utils/getSoftware.js';
import { useWindowResize } from '../../hooks/useWindowResize.js';
import { withRouter } from 'react-router-dom';

const Home = ({ match }) => {

      const { windowWidth } = useWindowResize();

      const enableParallax = windowWidth > 800;
      
      const software = getSoftware(match);

        return (
          <AppWrapper footerHero overlap>
            <Helmet>
                <title>Home</title>
            </Helmet>
            {software.id !== 'discord' && (
              <Helmet>
                  <title>Slack Bot</title>
                  <meta property="og:title" content="HouseCup.gg Slack Bot" />
                  <meta name="description" content='Encourage team recognition and gamification in a way your organization will enjoy with this fun, free, and easy to use Harry Potter™ universe and Hogwarts™ inspired Slack bot!' />
                  <meta property="og:description" content='Encourage team recognition and gamification in a way your organization will enjoy with this fun, free, and easy to use Harry Potter™ universe and Hogwarts™ inspired Slack bot!' />
              </Helmet>
            )}
            {software.id !== 'slack' && (
              <Helmet>
                  <title>Discord Bot</title>
                  <meta property="og:title" content="HouseCup.gg Slack Bot" />
                  <meta name="description" content='Encourage team recognition and gamification in a way your organization will enjoy with this fun, free, and easy to use Harry Potter™ universe and Hogwarts™ inspired Discord bot!' />
                  <meta property="og:description" content='Encourage team recognition and gamification in a way your organization will enjoy with this fun, free, and easy to use Harry Potter™ universe and Hogwarts™ inspired Discord bot!' />
              </Helmet>
            )}
            <HouseCupHero />
            <JoinTogether enableParallax={enableParallax}/>
            <TeamsOrIndividuals enableParallax={enableParallax}/>
            <CompaniesUsingBot />
            <ArticleCards />
            <FinalThingsToClick />
          </AppWrapper>
        );
    }

export default withRouter(Home);