import React, {Component} from 'react';
import classy from '../../utils/classy';
import style from './Visible.module.scss';
import VisibilitySensor from 'react-visibility-sensor';

export default class Visible extends Component {

    constructor() {
        super();
        this.state = {
            visible: false,
            active: true,
        }
    }

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                visible: isVisible,
                active: false,
            });
        }
    }

    render() {
        return (
            <VisibilitySensor partialVisibility={false} onChange={this.onChange} active={this.state.active}>
                <div className={classy(this.state.visible && style.visible, style.element, this.props.class)}>
                    {this.props.children}
                </div>
            </VisibilitySensor>
        );
    }
}