import React, { Component } from 'react';
import classy from '../../utils/classy';
import slack from './images/slack.svg';
import discord from './images/discord.png';
import style from './Documentation.module.scss';
import {Col, Row } from 'react-bootstrap';
import {AppWrapper, Card, Section, Heading, Clickable} from '../../components';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

export default class Documentation extends Component {
    render() {
        return (
            <AppWrapper headerAlwaysSticky>
                <Helmet>
                    <title>HouseCup.gg Bot Documentation</title>
                </Helmet>
                <Section size="small" className={style.section}>
                    <Heading kind="subtitle"><h1 className={style.subtitle}>HouseCup.gg Bot Documentation</h1></Heading>
                    <Heading className={style.heading} kind="h2">Which program are you using?</Heading>
                    <Row className={style.row}>
                        <Col xs="12" md="6" className={style.col}>
                            <Clickable href="/docs/slack/setup" className={style.programLink}>
                                <Card image={slack} alt="Slack Program" imageClass={classy(style.program, style.slack)} bodyClass={style.mediaBody} mediaClass={style.media} cardClass={style.card} contentClass={style.mediaContent} align="stretch" buttonClass={classy(style.button, style.slackButton)} title="Install for Slack" paragraph="Learn more about how to install HouseCup.gg in your Slack workspace." button="Learn More" cardClass={classy(style.card, style.slackCard)}/>
                            </Clickable>
                        </Col>
                        <Col xs="12" md="6">
                            <Clickable href="/docs/discord/setup" className={style.programLink}>
                                <Card image={discord} alt="Discord Program" imageClass={classy(style.program, style.discord)} bodyClass={style.mediaBody} mediaClass={style.media} cardClass={style.card} contentClass={style.mediaContent} align="stretch" buttonClass={classy(style.button, style.discordButton)} title="Install for Discord" paragraph="Learn more about how to install HouseCup.gg in your Discord channel." button="Learn More" cardClass={style.card}/>
                            </Clickable>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <p className={style.paragraph}>
                                Need help installing HouseCup.gg for Slack or Discord? Get support <Link to="/support" className={style.paragraphLink}>here</Link>.
                            </p>
                        </Col>
                    </Row>
                </Section>
            
          </AppWrapper>
        );
    }
}
